<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Customer" />
			<div>
				<HeroBox :title="title" :subtitle="subtitle" :color="color" />
			</div>
			<div class="margin-top-20">
				<customer-details-form :key="companiesIdentityDetailsReloadKey" />
			</div>
		</layout-wide>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import CustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/CustomerDetailsForm.vue'
import useCustomerDetailsForm from '@/_srcv2/views/pages/admin-reports/customer-list/useCustomerDetailsForm.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
export default {
	name: 'CustomerList',
	components: { CustomerDetailsForm, SubNavbar, HeroBox },
	setup() {
		const { companiesIdentityDetailsReloadKey } = useCustomerDetailsForm()
		// ? ---------------------------------------------------------------------
		const title = ref('Customer Page')
		const subtitle = ref(
			'Add new customers, add address to existing customers, and edit customers',
		)
		const color = ref('is-danger')
		// ? --------------------------------------------------------------------
		return {
			companiesIdentityDetailsReloadKey,
			title,
			subtitle,
			color,
		}
	},
}
</script>

<style scoped></style>
