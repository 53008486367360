<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Admin" />
		<section>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-button class="is-primary margin-top-20" type="submit" @click="getData"
				>Get Results</b-button
			>
			<div class="form-title margin-top-10">Incomes By Route Id</div>
			<div>
				<span class="text-bold text-blue margin-left-10"
					>Income:&nbsp; &nbsp; </span
				>{{ floatedCurrencyAddedNumber(income) }}
			</div>
			<div>
				<span class="text-bold text-blue margin-left-10">VAT:&nbsp; &nbsp;</span
				>{{ floatedCurrencyAddedNumber(vat) }}
			</div>
			<div class="margin-bottom-20">
				<span class="text-bold text-blue margin-left-10"
					>Total:&nbsp; &nbsp;</span
				>{{ floatedCurrencyAddedNumber(total) }}
			</div>
			<b-table :data="listArr" :columns="columns"></b-table>
		</section>
		<download-csv :data="listArr">
			<div class="margin-bottom-30 margin-top-30">
				<b-button class="is-success"> Download the Route Income List </b-button>
			</div>
		</download-csv>
	</layout-default-new>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import axios from 'axios'
// lsstore
import { lsGetTokenInfo } from '@/plugins/lsstore/index'
import GetRoutesIdsQuery from '@/_srcv2/pages/admin/incomes-by-routes/GetRoutesIds.graphql'
import GetInvoicesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getInvoicesByTimeInterval.query.gql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'

export default {
	name: 'IncomesByRouteId',
	components: {
		SubNavbar,
	},
	setup() {
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
			routeId: 'R-101',
		})
		// const today = ref(new Date())
		// const fetchDates = () => {
		// 	return new Promise((resolve, reject) => {
		// 		if (today.value !== null) {
		// 			selectedBeginningDate.value = today.value
		// 			selectedEndingDate.value = today.value
		// 			resolve('ok')
		// 		} else {
		// 			const reason = new Error('Today is null')
		// 			reject(reason)
		// 		}
		// 	})
		// }
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		// ** Get Incomes By Route Id
		const routeIdArray = ref([])
		// ! ------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchRoutes } = useQuery(
			GetRoutesIdsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// ** -----------------------------------------------------------------
		const { refetch: refetchInvoice } = useQuery(
			GetInvoicesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		// ! --------------------------------------------------------------
		const arrLength = computed(() => routeIdArray.value.length)
		const currentRouteKey = ref(1)
		// ! --------------------------------------------------------------
		const GetRowQuery = `
	       query GetIncomeByTimeIntervalAndByRouteIdQuery(
					$bd: date!
					$ed: date!
					$company_id: String!
					$route_id: String!
				) {
					goods_transactions_aggregate(
						where: {
							goods_transactions_document_transactions_rel: {
								our_company: { _eq: $company_id }
								invoice_date: { _gte: $bd, _lte: $ed }
								invoice_canceled: { _eq: false }
								invoice_lock: { _eq: true }
							}
							_invoices: {
								_addresses: { _cities: { route_id_by_city: { _eq: $route_id } } }
							}
						}
					) {
						aggregate {
							sum {
								line_price_total_credit
								vat_credit
							}
						}
					}
				}
	     `
		const incomeArray = ref([])
		const vatArray = ref([])
		const totalArray = ref([])
		const getRow = () => {
			parameters.routeId = routeIdArray.value[currentRouteKey.value - 1].id
			const routeName = routeIdArray.value[currentRouteKey.value - 1].name
			// ! -------------------------------------------------------------------
			const endpoint = 'https://gtbs-crm-backend-app.herokuapp.com/v1/graphql'
			const graphqlQuery = {
				query: GetRowQuery,
				variables: {
					company_id: Store.getters.getUserCurrentCompany,
					bd: parameters.beginningDate,
					ed: parameters.endingDate,
					route_id: parameters.routeId,
				},
			}
			// ! --------------------------------------------------------------
			axios({
				url: endpoint,
				method: 'post',
				headers: getHeaders(),
				data: graphqlQuery,
			})
				.then((result) => {
					const rowObj =
						result.data.data?.goods_transactions_aggregate?.aggregate?.sum
					tableValues.value.push({
						type: parameters.routeId + ' ' + routeName,
						income: floatedCurrencyAddedNumber(rowObj?.line_price_total_credit),
						vat: floatedCurrencyAddedNumber(rowObj?.vat_credit),
						total: floatedCurrencyAddedNumber(
							rowObj?.line_price_total_credit + rowObj?.vat_credit,
						),
					})
					incomeArray.value.push(rowObj?.line_price_total_credit || 0)
					vatArray.value.push(rowObj?.vat_credit || 0)
					totalArray.value.push(
						rowObj?.line_price_total_credit + rowObj?.vat_credit || 0,
					)
				})
				.then(() => {
					if (currentRouteKey.value < arrLength.value) {
						getRow(currentRouteKey.value++)
					} else {
						const getSum = (total, num) => {
							return total + num
						}
						tableValues.value.push({
							type: 'Totals',
							income: floatedCurrencyAddedNumber(
								incomeArray.value.reduce(getSum, 0),
							),
							vat: floatedCurrencyAddedNumber(vatArray.value.reduce(getSum, 0)),
							total: floatedCurrencyAddedNumber(
								totalArray.value.reduce(getSum, 0),
							),
						})
						alert('Data is fetched')
					}
				})
		}
		// ** get headers
		const getHeaders = () => {
			const headers = {}
			const token = lsGetTokenInfo()
			if (token) {
				headers.authorization = `Bearer ${token}`
			}
			return headers
		}
		// ! --------------------------------------------------------------
		const income = ref(0)
		const vat = ref(0)
		const total = computed(() => income.value + vat.value)
		const getData = () => {
			currentRouteKey.value = 1
			tableValues.value = []
			incomeArray.value = []
			vatArray.value = []
			totalArray.value = []
			getRow()
			refetchInvoice().then((result) => {
				income.value =
					result.data.goods_transactions_aggregate.aggregate.sum.line_price_total_credit
				vat.value =
					result.data.goods_transactions_aggregate.aggregate.sum.vat_credit
			})
		}
		// ! -------------------------------------------------------------------------
		let tableValues = ref([])
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'type',
				label: 'Route Id',
				width: '170',
			},
			{
				field: 'income',
				label: 'Income',
				width: '150',
			},
			{
				field: 'vat',
				label: 'VAT',
				width: '150',
			},
			{
				field: 'total',
				label: 'Total',
				width: '150',
			},
		]
		// ** ----------------------------------------------------
		const { floatedCurrencyAddedNumber } = useReportNumber()
		onMounted(() => {
			refetchRoutes()
				.then((result) => {
					return result.data.addresses_cities
				})
				.then((array) => {
					routeIdArray.value = array.map((item) => {
						return {
							id: item.route_id_by_city,
							name: item._adresses_route.route_name,
						}
					})
				})
				.then(() => getData())
				.then(() => {
					refetchInvoice()
				})
		})
		return {
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			getData,
			listArr,
			columns,
			income,
			vat,
			total,
			floatedCurrencyAddedNumber,
		}
	},
}
</script>

<style scoped>
.form-title {
	font-weight: bolder;
	font-size: 1.5em;
	color: #9b1c1c;
	margin-left: 5px;
	margin-bottom: 20px;
}
.form-btn {
	margin-left: 20px;
}
.margin-top-20 {
	margin-top: 20px;
}
</style>
